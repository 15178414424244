import React, { ReactElement, useEffect } from 'react';
import YouTube from 'react-youtube';

const opts = {
	height: '160',
	width: '320'
}

export default function Music(): ReactElement {
	useEffect(() => {
		document.title = 'Roger Boll - Music'
	}, []);
	
	return (
		<>
			<div style={{ margin: '40px auto', width: '500px' }}>
				<div style={{ textAlign: 'center' }}>
					<div style={{ padding: '24px' }}>
						<YouTube videoId="4zF7PqURxOA" opts={opts} />
					</div>
					<div style={{ padding: '24px' }}>
						<YouTube videoId="kjQ-KTbwq_Q" opts={opts} />
					</div>
					<div style={{ padding: '24px' }}>
						<YouTube videoId="-xAcsvBfrYQ" opts={opts} />
					</div>
					<div style={{ padding: '24px' }}>
						<YouTube videoId="RIAtZzlAh4w" opts={opts} />
					</div>
				</div>
			</div>
		</>
	);
}
