import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function Menu() {
	const location = useLocation();

	return (
		<div id="menu-container">
			<div id="menu">
				<ol>
					<li>
						<Link to="/Music" className={location.pathname.includes("Music") ? 'current-menu-link' : undefined}>Music</Link>
					</li>
					<li>
						<Link to="/Contact" className={location.pathname.includes("Contact") ? 'current-menu-link' : undefined}>Contact</Link>
					</li>
					<li>
						<Link to="/Calendar" className={location.pathname.includes("Calendar") ? 'current-menu-link' : undefined}>Calendar</Link>
					</li>
					<li>
						<Link to="/Bio" className={location.pathname.includes("Bio") ? 'current-menu-link' : undefined}>Bio</Link>
					</li>
				</ol>
			</div>
		</div>

	);
}
