import React, { ReactElement, useEffect } from 'react';
import Picture4 from './assets/images/Picture4.png';

export default function Home(): ReactElement {
	useEffect(() => {
		document.title = 'Roger Boll';
	}, []);
	
	return (
		<img alt="Roger Boll Music" src={Picture4} />
	);
}
