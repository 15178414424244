import React from 'react';
import './Site.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from 'react-router-dom';
import Home from './Home';
import Music from './Music';
import Contact from './Contact';
import Calendar from './Calendar';
import Bio from './Bio';
import Menu from './Menu';

function App(props: any) {
  return (
    <div id="container">
      <Router>
        <div id="header">
          <h3><Link to="/">Roger Boll</Link></h3>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
          </Switch>
          <Menu />
        </div>
        <Switch>
          <Route path="/Music"><Music/></Route>
          <Route path="/Contact"><Contact/></Route>
          <Route path="/Calendar"><Calendar/></Route>
          <Route path="/Bio"><Bio/></Route>
        </Switch>
      </Router>


      <div id="footer">
        ©{new Date().getFullYear()} Roger Boll. All Rights Reserved.
      </div>
    </div>
  );
}

export default App;
