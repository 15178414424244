import React, { ReactElement, useEffect } from 'react';

export default function Calendar(): ReactElement {
	useEffect(() => {
		document.title = 'Roger Boll - Calendar';
	}, []);
	
	return (
		<>
			<p style={{textAlign: 'center'}}>
				<iframe title="Roger Boll Calendar" src="https://www.google.com/calendar/embed?src=kinsmentrio%40gmail.com&ctz=America/Chicago"
					style={{border: 0}} width="800" height="600" frameBorder="0" scrolling="no"></iframe>
			</p>
		</>
	);
}
