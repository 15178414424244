import React, { ReactElement, useEffect } from 'react';

import Picture8 from './assets/images/IMG_2793.png';

export default function Contact(): ReactElement {
	useEffect(() => {
		document.title = 'Roger Boll - Contact';
	}, []);
	
	return (
		<>
			<p style={{marginTop: '40px', textAlign: 'center'}}>
				Music for any occasion!
			</p>

			<table style={{margin: 'auto', width: '600px'}}>
				<tr>
					<td style={{borderTop: '1px solid #666666', borderBottom: '1px solid #666666', padding: '10px'}}>
						Cell: 414.861.3240
					</td>
					<td style={{borderTop: '1px solid #666666', borderBottom: '1px solid #666666', padding: '10px'}}>
						Email: bollacres@aol.com
					</td>
				</tr>
			</table>

			<div style={{margin: '40px auto', textAlign: 'center'}}>
				<img alt="Roger Boll" src={Picture8} />
			</div>
		</>
	);
}
