import React, { ReactElement, useEffect } from 'react';

import IMG_8046 from './assets/images/IMG_8046.png';

export default function Bio(): ReactElement {
	useEffect(() => {
		document.title = 'Roger Boll - Bio';
	}, []);
	
	return (
		<>
			<div style={{ margin: '40px auto', width: '500px', textAlign: 'justify' }}>
				<p>
					Roger has been performing in the greater Milwaukee area for more than 50 years, solo and with the Roger Boll Band. He performs solo at many senior living complexes and senior care facilities as well as private parties, weddings and community events. He plays a CIAO or Roland reedless accordion, both of which have hundreds of sounds programmed into them. The versatility of these instruments, along with Roger’s talent, combine to create a program of music that will take you back to songs you’ve loved through the years. Please join us for a very entertaining program. You will find yourself singing along, tapping your toes, and perhaps dancing to Roger’s music. Don’t forget to tell your friends and neighbors!!
				</p>
			</div>
			<hr style={{ width: '500px' }} />
			<div style={{ margin: 'auto' }}>
				<p style={{ textAlign: 'center', color: '#999999' }}>Roger Boll at a small Pizza Shop<br /><br />
					<img alt="Roger Boll at a small Pizza Shop" src={IMG_8046} />
				</p>
			</div>
		</>
	);
}
